import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import appSettings from '../../config/settings';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { STATIC_PAGES } from '../../routing/routeConfiguration';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { withViewport } from '../../util/uiHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { ensureCompanySearchOrUserType, getUserType } from '../../util/data';
import {
  Button,
  InlineTextButton,
  LimitedAccessBanner,
  LinkedLogo,
  Modal,
  ModalMissingInformation,
  NamedLink,
  PendingApprovalBanner,
} from '../../components';

import MenuIcon from './MenuIcon';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';
import HeroBoxes from '../../containers/LandingPage/HeroBoxes/HeroBoxes';

import css from './Topbar.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show, currentPage } = props;
  const isSearchPage = currentPage === 'SearchPage';
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
    [css.genericErrorSearchPage]: show && isSearchPage,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      heroBoxesModalOpen: false,
    };

    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleHeroBoxesModalClose = this.handleHeroBoxesModalClose.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleLogout() {
    const { onLogout, history, routeConfiguration } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  handleHeroBoxesModalClose() {
    this.setState({
      heroBoxesModalOpen: false,
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserListing,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      config,
      companyProfileData,
      talentProfileData,
    } = this.props;

    const { mobilemenu } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const userType = getUserType(currentUser);

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isLandingPage = currentPage === 'LandingPage';
    const isAboutPage = currentPage === 'AboutPage';
    const isLoginPage = currentPage === 'LoginPage';
    const isStaticPages = STATIC_PAGES.includes(currentPage);
    const isCompanyUser = ensureCompanySearchOrUserType(userType);

    const showMobileLogo = isMobileLayout && !isLandingPage;
    const showModalMissingInformation = false;
    const loginOrLogoutButton = isAuthenticated ? (
      <InlineTextButton
        type="button"
        className={css.containerContentLink}
        onClick={this.handleLogout}
      >
        <FormattedMessage id="Topbar.logOut" />
      </InlineTextButton>
    ) :
      // isLoginPage ? (
      //   <NamedLink className={css.containerContentLink} name="LandingPage">
      //     <FormattedMessage id="Topbar.signUp" />
      //   </NamedLink>
      // ) : 
      (
        <NamedLink className={css.containerContentLink} name="LoginPage">
          <FormattedMessage id="Topbar.logIn" />
        </NamedLink>
      );

    const mobileLogo = showMobileLogo ? (
      <div className={css.containerLogo}>
        <LinkedLogo format={'mobile'} alt={intl.formatMessage({ id: 'Topbar.logoIcon' })} />
      </div>
    ) : null;

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        onCloseMobileMenu={this.handleMobileMenuClose}
        notificationCount={notificationCount}
        currentPage={currentPage}
      />
    );

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <PendingApprovalBanner
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
          currentUserListing={currentUserListing}
          currentPage={currentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          {mobileLogo}
          <div
            className={classNames(css.containerContent, {
              [css.containerContentStaticPages]: isStaticPages,
            })}
          >
            {loginOrLogoutButton}
            <Button
              rootClassName={css.menu}
              onClick={this.handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <MenuIcon className={css.menuIcon} />
              {/* {notificationDot} */}
            </Button>
          </div>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            intl={intl}
            isAuthenticated={isAuthenticated}
            isLandingPage={isLandingPage}
            isAboutPage={isAboutPage}
            isCompanyUser={isCompanyUser}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            appConfig={config}
            onOpenHeroBoxesModal={() =>
              this.setState({
                heroBoxesModalOpen: true,
              })
            }
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          containerClassName={css.mobileMenuModal}
          closeButtonClassName={css.mobileMenuModalCloseButton}
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>

        {showModalMissingInformation ? (
          <ModalMissingInformation
            id="MissingInformationReminder"
            containerClassName={css.missingInformationModal}
            currentUser={currentUser}
            currentUserHasListings={currentUserHasListings}
            currentUserHasOrders={currentUserHasOrders}
            location={location}
            onManageDisableScrolling={onManageDisableScrolling}
            onResendVerificationEmail={onResendVerificationEmail}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            sendVerificationEmailError={sendVerificationEmailError}
          />
        ) : null}

        <Modal
          id="HeroBoxesModal"
          containerClassName={css.heroBoxesModal}
          isOpen={this.state.heroBoxesModalOpen}
          onClose={this.handleHeroBoxesModalClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <HeroBoxes
            className={css.heroBoxes}
            intl={intl}
            isAuthenticated={isAuthenticated}
            currentUser={currentUser}
            companyProfileData={companyProfileData}
            talentProfileData={talentProfileData}
            isPopUp
          />
        </Modal>

        <GenericError show={showGenericError} currentPage={currentPage} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // profileData
  companyProfileData: object,
  talentProfileData: object,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedTopbar = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  return (
    <TopbarComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  );
};

const Topbar = withViewport(EnhancedTopbar);
Topbar.displayName = 'Topbar';

export default Topbar;
