import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';

// Import shared components
import {
  Form,
  SecondaryButton,
  FieldTextInput,
  FieldPhoneNumberInput,
  FieldBirthdayInput,
} from '../../../../components';
// Import modules from this directory
import PanelFooter from '../PanelFooter';
import css from './CreateTalentBasicsForm.module.css';

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateTalentBasicsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="CreateTalentBasicsForm.createListingDraftError" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

const CreateTalentBasicsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    validate={() => {
      const errors = {};
      if (props.isEmailTaken) {
        const emailTakenMessage = props.intl.formatMessage({
          id: 'CreateCompanyBasicsForm.emailTaken',
        });
        errors.email = emailTakenMessage;
      }
      return errors;
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        formId,
        handleSubmit,
        onRedirectToPreviousTab,
        onEmailChangeWithDebounce,
        isEmailTaken,
        intl,
        invalid,
        pristine,
        values,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;
      
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      const lastNameRequiredMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const emailRequiredMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || isEmailTaken;
      
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <div className={css.fieldsWrapper}>
            <div className={css.fieldFlex}>
              <FieldTextInput
                id={`${formId}.firstName`}
                name="firstName"
                className={css.field}
                type="text"
                placeholder={intl.formatMessage({
                  id: 'CreateTalentBasicsForm.firstNamePlaceholder',
                })}
                validate={firstNameRequired}
              />
              <FieldTextInput
                id={`${formId}.lastName`}
                name="lastName"
                className={css.field}
                type="text"
                placeholder={intl.formatMessage({
                  id: 'CreateTalentBasicsForm.lastNamePlaceholder',
                })}
                validate={lastNameRequired}
              />
            </div>

            <div className={css.fieldFlex}>
              <FieldTextInput
                id={`${formId}.email`}
                name="email"
                className={css.field}
                type="email"
                placeholder={intl.formatMessage({ id: 'CreateTalentBasicsForm.emailPlaceholder' })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <OnChange name="email">
                {(value, previous) => onEmailChangeWithDebounce({ email: value })}
              </OnChange>
              <FieldPhoneNumberInput
                id={`${formId}.phone`}
                name="phone"
                className={css.field}
                type="text"
                placeholder={intl.formatMessage({ id: 'CreateTalentBasicsForm.phonePlaceholder' })}
              />
            </div>

            <div className={css.field}>
              <label>DOB</label>
              <FieldBirthdayInput
                id={`${formId}.birthday`}
                name="birthday"
                valueFromForm={values.birthday}
                // validate={validators.required(
                //   intl.formatMessage({
                //     id: 'CreateTalentBasicsForm.birhdayRequired',
                //   })
                // )}
              />
            </div>
          </div>

          <PanelFooter onRedirectToPreviousTab={onRedirectToPreviousTab}>
            <SecondaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </SecondaryButton>
          </PanelFooter>
        </Form>
      );
    }}
  />
);

CreateTalentBasicsFormComponent.defaultProps = {
  className: null,
  formId: 'CreateTalentBasicsForm',
  fetchErrors: null,
};

CreateTalentBasicsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(CreateTalentBasicsFormComponent);
