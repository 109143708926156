import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, number, shape, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

// Import util modules
import { useConfiguration } from '../../../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';

// Import shared components
import {
  FieldCheckboxGroup,
  FieldRadioButtonGroup,
  FieldSelect,
  FieldTextInput,
  Form,
  SecondaryButton,
} from '../../../../components';
// Import modules from this directory
import { withViewport } from '../../../../util/uiHelpers';
import { LocationSearchField } from '../../../SearchPage/LocationFilter/LocationFilter';
import PanelFooter from '../PanelFooter';
import css from './CreateTalentWorkForm.module.css';

const TAB_LISTING_FIELD_IDS = [
  'industry',
  'primarySpecialty',
  'secondarySpecialty',
  'interests',
  'expertise',
];

const ARCHITECTURE_KEY = 'architecture';
const INDUSTRY_BOTH_COMBINED_KEY = 'architecture,interior-design,both';
const INDUSTRY_BOTH_KEY = 'both';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAPBOX_MAP_CITY_TYPES = ['place'];

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, submitFormError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateTalentWorkForm.updateFailed" />
  ) : null;
  const submitFormErrorMessage = submitFormError ? (
    <FormattedMessage id="CreateTalentWorkForm.submitFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  } else if (submitFormError) {
    return <p className={css.error}>{submitFormErrorMessage}</p>;
  }
  return null;
};

const CreateTalentWorkFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        formId,
        handleSubmit,
        intl,
        onRedirectToPreviousTab,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        viewport,
      } = formRenderProps;

      const config = useConfiguration();
      const listingConfig = config.listing;
      const listingFields = listingConfig.listingFields.filter(l =>
        TAB_LISTING_FIELD_IDS.includes(l.key)
      );

      const industry = listingFields.find(l => l.key === 'industry');
      const industryOptions = industry ? industry.enumOptions : [];

      const expertise = listingFields.find(l => l.key === 'expertise');
      const expertiseOptions = expertise ? expertise.enumOptions : [];

      const primarySpecialty = listingFields.find(l => l.key === 'primarySpecialty');
      const primarySpecialtyOptions = primarySpecialty ? primarySpecialty.enumOptions : [];

      const secondarySpecialty = listingFields.find(l => l.key === 'secondarySpecialty');
      const secondarySpecialtyOptions = secondarySpecialty ? secondarySpecialty.enumOptions : [];

      const interests = listingFields.find(l => l.key === 'interests');
      const interestsOptions = interests ? interests.enumOptions : [];

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>
              {intl.formatMessage({
                id: 'CreateTalentWorkForm.locationLabel',
              })}
            </label>
            <p className={css.fieldText}>
              {intl.formatMessage({
                id: 'CreateTalentWorkForm.locationText',
              })}
            </p>

            <LocationSearchField
              intl={intl}
              placeholder={intl.formatMessage({
                id: 'CreateCompanyBasicsForm.addressPlaceholder',
              })}
              isMobile={isMobileLayout}
              types={MAPBOX_MAP_CITY_TYPES}
              useCityPredictions
              onLocationChange={()=>{}}
            />

            {/* <div className={css.fieldFlex}>
              <FieldTextInput
                id={`${formId}.addressLine1`}
                name="address.addressLine1"
                className={css.field}
                type="text"
                placeholder={intl.formatMessage({
                  id: 'CreateCompanyBasicsForm.addressPlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.addressRequired',
                  })
                )}
              />
              <FieldTextInput
                id={`${formId}.address.city`}
                name="address.city"
                className={css.field}
                type="text"
                placeholder={intl.formatMessage({ id: 'CreateTalentWorkForm.cityPlaceholder' })}
                validate={validators.required(
                  intl.formatMessage({ id: 'CreateTalentWorkForm.cityRequired' })
                )}
              />
              <FieldTextInput
                id={`${formId}.address.zip`}
                name="address.zip"
                className={css.field}
                type="text"
                placeholder={intl.formatMessage({ id: 'CreateTalentWorkForm.zipPlaceholder' })}
                validate={validators.required(
                  intl.formatMessage({ id: 'CreateTalentWorkForm.zipRequired' })
                )}
              />
            </div> */}
          </div>

          <FieldRadioButtonGroup
            className={classNames(css.field, css.radioFields)}
            id={`${formId}.${industry.key}`}
            name={industry.key}
            label={industry.saveConfig.label}
            options={industryOptions.map(o => ({
              key: o.option === INDUSTRY_BOTH_COMBINED_KEY ? INDUSTRY_BOTH_KEY : o.option,
              label: o.label,
              text:
                o.option === ARCHITECTURE_KEY
                  ? intl.formatMessage({ id: 'CreateTalentWorkForm.architectureLabelMessage' })
                  : null,
            }))}
          />

          <div className={css.fieldsFlex}>
            <div className={css.field}>
              <label className={css.fieldLabel}>{expertise.saveConfig.label}</label>
              <p className={css.fieldText}>
                {intl.formatMessage({
                  id: 'CreateTalentWorkForm.secondarySpecialtyText',
                })}
              </p>
              <FieldCheckboxGroup
                className={classNames(css.checkboxFields, css.twoColumns)}
                id={`${formId}.${expertise.key}`}
                name={expertise.key}
                options={expertiseOptions.map(o => ({ key: o.option, label: o.label }))}
                twoColumns
                // validate={validators.nonEmptyArray(expertise.saveConfig.requiredMessage)}
              />
            </div>
          </div>

          <div className={css.fieldsFlex}>
            <div className={css.fieldContainer}>
              <label className={css.fieldLabel}>{primarySpecialty.saveConfig.label}</label>
              <p className={css.fieldText}>
                <FormattedMessage id="CreateTalentWorkForm.specialtyText" />
              </p>

              <FieldSelect
                id={`${formId}.primarySpecialty`}
                name="primarySpecialty"
                validate={validators.required(primarySpecialty.saveConfig.requiredMessage)}
                required
              >
                <option disabled value="">
                  {primarySpecialty.saveConfig.placeholderMessage}
                </option>
                {primarySpecialtyOptions.map(o => {
                  return (
                    <option key={o.option} value={o.option}>
                      {o.label}
                    </option>
                  );
                })}
              </FieldSelect>
            </div>
            <div className={css.field}>
              <label className={css.fieldLabel}>{secondarySpecialty.saveConfig.label}</label>
              <p className={css.fieldText}>
                {intl.formatMessage({
                  id: 'CreateTalentWorkForm.secondarySpecialtyText',
                })}
              </p>
              <FieldCheckboxGroup
                className={classNames(css.checkboxFields, css.twoColumns)}
                id={`${formId}.${secondarySpecialty.key}`}
                name={secondarySpecialty.key}
                options={secondarySpecialtyOptions.map(o => ({ key: o.option, label: o.label }))}
                twoColumns
              />
            </div>
          </div>

          <div>
            <label className={css.fieldLabel}>{interests.saveConfig.label}</label>
            <p className={css.fieldText}>
              {intl.formatMessage({
                id: 'CreateTalentWorkForm.interestsText',
              })}
            </p>
            <FieldCheckboxGroup
              className={classNames(css.checkboxFields, css.interestsCheckboxFields)}
              id={`${formId}.${interests.key}`}
              name={interests.key}
              options={interestsOptions.map(o => ({ key: o.option, label: o.label }))}
            />
          </div>

          <PanelFooter onRedirectToPreviousTab={onRedirectToPreviousTab}>
            <SecondaryButton
              className={css.submitButton}
              spinnerClassName={css.submitButtonSpinner}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </SecondaryButton>
          </PanelFooter>
        </Form>
      );
    }}
  />
);

CreateTalentWorkFormComponent.defaultProps = {
  className: null,
  formId: 'CreateTalentWorkForm',
  fetchErrors: null,
};

CreateTalentWorkFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  onChange: func,
};

export default compose(
  injectIntl,
  withViewport
)(CreateTalentWorkFormComponent);
