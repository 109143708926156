import React from 'react';
import { useAnimatedInView } from './AboutPage.shared';
import ConfidentialSVG from '../../assets/Confidential.svg';
import FreeSVG from '../../assets/Free.svg';
import VettedSVG from '../../assets/Vetted.svg';
import { useConfiguration } from '../../context/configurationContext';
import SignupForm from '../LandingPage/SignupForm/SignupForm';

import css from './AboutPage.module.css';
import { emailFormatValidWithoutForm } from '../../util/validators';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { generateRandomUUID } from '../../util/uuid';
import {
  saveProfileDataToLocalStorage as saveTalentProfileDataToLocalStorage,
  updateorcreateMailChimpMember,
} from '../CreateTalentPage/CreateTalentPage.duck';
import { saveProfileDataToLocalStorage as saveCompanyProfileDataToLocalStorage } from '../CreateCompanyPage/CreateCompanyPage.duck';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { emailTaken } from '../../ducks/auth.duck';
import classNames from 'classnames';

export const SectionHome = props => {
  const { sectionId, isMobileLayout, errors, isEmailTaken, initialValues } = props;

  const { ref, AnimatedDiv } = useAnimatedInView({
    animate: !isMobileLayout,
    threshold: 0.7,
    triggerOnce: true,
  });

  const DEBOUNCE_WAIT_TIME = 200;
  const config = useConfiguration();
  const dispatch = useDispatch();
  const history = useHistory();
  const routes = useRouteConfiguration();
  const handleSubmit = values => {
    const { email } = values;

    if (emailFormatValidWithoutForm(email)) {
      dispatch(emailTaken(values));
    }
  };

  const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  const handleChangeWithDebounce = values => {
    return debouncedSubmit(values);
  };

  return (
    <div id={sectionId}>
      <div  className={css.mainContent}>
        <div className={css.leftContent}>
          <div className={css.mainTitle}>Design your career with Duke Talent</div>
          <div className={css.mobileMap}>
            <div className={css.mainDescription}>
              Redefining the search process with a vetted and nimble online platform that
              matches leading designers and architects with a curated list of top studios
            </div>
            <div className={css.mainDescription2}>Studios That Do What You Do</div>
          </div>

          <div className={css.iconDesktopContent}>
            <div className={css.svgContent}>
              <img alt="" src={VettedSVG} />
              <div>Vetted</div>
            </div>
            <div className={css.svgContent}>
              <img alt="" src={ConfidentialSVG} />
              <div>Confidential</div>
            </div>
            <div className={css.svgContent}>
              <img alt="" src={FreeSVG} />
              <div>Free</div>
            </div>
          </div>
        </div>
        <div className={css.formContent}>
          <div className={css.formContentTitle}>Interested in learning more?</div>
          {/* <div className={css.formContentDescription}>
          Start connecting with thousands of top designers and studios
        </div> */}
          <SignupForm
            className={css.form}
            saveActionMsg="Submit"
            initialValues={initialValues}
            onSubmit={async values => {
              const { id, firstName, lastName, email, name, registerType } = values;
              const updateValues = {
                id: id ? id : generateRandomUUID(),
                firstName,
                lastName,
                email,
                name,
                registerType,
              };

              if (registerType === 'candidate') {
                const createMailChimpObject = {
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  step: 0,
                  registertype: 'Candidates',
                };
                Promise.all([
                  dispatch(updateorcreateMailChimpMember(createMailChimpObject)),
                  dispatch(saveTalentProfileDataToLocalStorage(updateValues)),
                ]);

                history.push(
                  createResourceLocatorString('NewTalentPage', routes, updateValues, {})
                );
              } else {
                const createMailChimpObject = {
                  firstName: name,
                  email: email,
                  step: 0,
                  registertype: 'Clients',
                };
                Promise.all([
                  dispatch(updateorcreateMailChimpMember(createMailChimpObject)),
                  dispatch(saveCompanyProfileDataToLocalStorage(updateValues)),
                ]);
                history.push(
                  createResourceLocatorString('NewCompanyPage', routes, updateValues, {})
                );
              }
            }}
            marketplaceCurrency={config.currency}
            onEmailChangeWithDebounce={handleChangeWithDebounce}
            isEmailTaken={isEmailTaken}
            fetchErrors={errors ?? {}}
            autoFocus
          />
        </div>
      </div>
      <div className={classNames(css.videoResponsive,css.videoPosition)}>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/492J8i7R0do?si=0QcE8E67o99g_hrO`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded YouTube"
        />
      </div>
    </div>
  );
};

export default SectionHome;
