import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { useConfiguration } from '../../../../context/configurationContext';
import { required, nonEmptyArray } from '../../../../util/validators';

// Import shared components
import {
  Form,
  SecondaryButton,
  FieldRadioButtonGroup,
  FieldSelect,
  FieldTextInput,
  FieldCheckboxGroup,
} from '../../../../components';
// Import modules from this directory
import PanelFooter from '../PanelFooter';
import css from './CreateCompanyIndustryForm.module.css';

const TAB_LISTING_FIELD_IDS = [
  'industry',
  'primarySpecialty',
  'secondarySpecialty',
  'style',
  'ad100',
  'wfhPolicy',
  'openTo',
];

const INDUSTRY_BOTH_COMBINED_KEY = 'architecture,interior-design,both';
const INDUSTRY_BOTH_KEY = 'both';

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateCompanyIndustryForm.updateFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

const CreateCompanyIndustryFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        formId,
        handleSubmit,
        onRedirectToPreviousTab,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const config = useConfiguration();
      const listingConfig = config.listing;
      const listingFields = listingConfig.listingFields.filter(l =>
        TAB_LISTING_FIELD_IDS.includes(l.key)
      );

      const industry = listingFields.find(l => l.key === 'industry');
      const industryOptions = industry ? industry.enumOptions : [];

      const primarySpecialty = listingFields.find(l => l.key === 'primarySpecialty');
      const primarySpecialtyOptions = primarySpecialty ? primarySpecialty.enumOptions : [];

      const secondarySpecialty = listingFields.find(l => l.key === 'secondarySpecialty');
      const secondarySpecialtyOptions = secondarySpecialty ? secondarySpecialty.enumOptions : [];

      const style = listingFields.find(l => l.key === 'style');
      const styleOptions = style ? style.enumOptions : [];

      const openTo = listingFields.find(l => l.key === 'openTo');
      const openToOptions = openTo ? openTo.enumOptions : [];

      const ad100 = listingFields.find(l => l.key === 'ad100');
      const ad100Options = ad100 ? ad100.enumOptions : [];

      const wfhPolicy = listingFields.find(l => l.key === 'wfhPolicy');
      const wfhPolicyOptions = wfhPolicy ? wfhPolicy.enumOptions : [];

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <FieldRadioButtonGroup
            className={classNames(css.field, css.radioFields)}
            id={`${formId}.${industry.key}`}
            name={industry.key}
            label={industry.saveConfig.label}
            options={industryOptions.map(o => ({
              key: o.option === INDUSTRY_BOTH_COMBINED_KEY ? INDUSTRY_BOTH_KEY : o.option,
              label: o.label,
            }))}
          />

          <div className={css.fieldsFlex}>
            <div className={css.fieldContainer}>
              <label className={css.fieldLabel}>{primarySpecialty.saveConfig.label}</label>
              <p className={css.fieldText}>
                <FormattedMessage id="CreateCompanyIndustryForm.specialtyText" />
              </p>

              <FieldSelect
                id={`${formId}.primarySpecialty`}
                name="primarySpecialty"
                validate={required(primarySpecialty.saveConfig.requiredMessage)}
                required
              >
                <option value="" disabled>
                  {primarySpecialty.saveConfig.placeholderMessage}
                </option>
                {primarySpecialtyOptions.map(o => {
                  return (
                    <option key={o.option} value={o.option}>
                      {o.label}
                    </option>
                  );
                })}
              </FieldSelect>
            </div>
            <div className={css.field}>
              <label className={css.fieldLabel}>{secondarySpecialty.saveConfig.label}</label>
              <p className={css.fieldText}>
                {intl.formatMessage({
                  id: 'CreateCompanyIndustryForm.secondarySpecialtyText',
                })}
              </p>
              <FieldCheckboxGroup
                className={css.checkboxFields}
                id={`${formId}.${secondarySpecialty.key}`}
                name={secondarySpecialty.key}
                options={secondarySpecialtyOptions.map(o => ({ key: o.option, label: o.label }))}
                twoColumns
              />
            </div>
          </div>

          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>
              <FormattedMessage id="CreateCompanyIndustryForm.style" />
            </label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateCompanyIndustryForm.styleText" />
            </p>
            <FieldCheckboxGroup
              className={classNames(css.checkboxFields, css.stylesCheckboxFields)}
              id={`${formId}.${style.key}`}
              name={style.key}
              options={styleOptions.map(o => ({ key: o.option, label: o.label }))}
              validate={nonEmptyArray(style.saveConfig.requiredMessage)}
            />
          </div>

          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>{ad100.saveConfig.label}</label>
            <p className={css.fieldText}>
              {intl.formatMessage({
                id: 'CreateCompanyIndustryForm.ad100Text',
              })}
            </p>
            <FieldCheckboxGroup
              className={classNames(css.checkboxFields, css.oneColumn)}
              id={`${formId}.${ad100.key}`}
              name={ad100.key}
              options={ad100Options.map(o => ({ key: o.option, label: o.label }))}
            />
          </div>

          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>{wfhPolicy.saveConfig.label}</label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateCompanyIndustryForm.wfhPolicyText" />
            </p>
            <FieldSelect
              id={`${formId}.wfhPolicy`}
              name="wfhPolicy"
              validate={required(wfhPolicy.saveConfig.requiredMessage)}
              required
            >
              <option value="" disabled>
                {wfhPolicy.saveConfig.placeholderMessage}
              </option>
              {wfhPolicyOptions.map(o => {
                return (
                  <option key={o.option} value={o.option}>
                    {o.label}
                  </option>
                );
              })}
            </FieldSelect>
          </div>

          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>
              <FormattedMessage id="CreateCompanyIndustryForm.openToLabel" />
            </label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateCompanyIndustryForm.openToText" />
            </p>
            <FieldSelect
              id={`${formId}.openTo`}
              name="openTo"
              validate={required(openTo.saveConfig.requiredMessage)}
              required
            >
              <option disabled value="">
                {openTo.saveConfig.placeholderMessage}
              </option>
              {openToOptions.map(o => {
                return (
                  <option key={o.option} value={o.option}>
                    {o.label + ' candidates'}
                  </option>
                );
              })}
            </FieldSelect>
          </div>

          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>
              <FormattedMessage id="CreateCompanyIndustryForm.description" />
            </label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateCompanyIndustryForm.descriptionText" />
            </p>
            <FieldTextInput
              className={css.description}
              id={`${formId}.description`}
              name="description"
              type="textarea"
              placeholder={intl.formatMessage({
                id: 'CreateCompanyIndustryForm.descriptionPlaceholder',
              })}
              validate={required(
                intl.formatMessage({
                  id: 'CreateCompanyIndustryForm.descriptionRequired',
                })
              )}
            />
          </div>

          <PanelFooter onRedirectToPreviousTab={onRedirectToPreviousTab}>
            <SecondaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </SecondaryButton>
          </PanelFooter>
        </Form>
      );
    }}
  />
);

CreateCompanyIndustryFormComponent.defaultProps = {
  className: null,
  formId: 'CreateCompanyIndustryForm',
  fetchErrors: null,
};

CreateCompanyIndustryFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(CreateCompanyIndustryFormComponent);
