import React, { useState } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { useConfiguration } from '../../../../context/configurationContext';
import * as validators from '../../../../util/validators';

// Import shared components
import {
  Form,
  SecondaryButton,
  FieldTextInput,
  FieldSelect,
  InlineTextButton,
} from '../../../../components';
// Import modules from this directory
import PanelFooter from '../PanelFooter';
import css from './CreateCompanyBasicsForm.module.css';
import { LocationSearchField } from '../../../SearchPage/LocationFilter/LocationFilter';
import { withViewport } from '../../../../util/uiHelpers';

const NAME_MAX_LENGTH = 60;
const TAB_LISTING_FIELD_IDS = ['companySize'];
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAPBOX_MAP_CITY_TYPES = ['place'];

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateCompanyBasicsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="CreateCompanyBasicsForm.createListingDraftError" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

const CreateCompanyBasicsFormComponent = props => {
  const hasSecondaryAddress = props?.initialValues?.secondaryAddress;
  const [secondaryAddressVisible, setSecondaryAddressVisible] = useState(hasSecondaryAddress);
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      validate={() => {
        const errors = {};
        if (props.isEmailTaken) {
          const emailTakenMessage = props.intl.formatMessage({
            id: 'CreateCompanyBasicsForm.emailTaken',
          });
          errors.email = emailTakenMessage;
        }

        return errors;
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          formId,
          handleSubmit,
          onRedirectToPreviousTab,
          onEmailChangeWithDebounce,
          isEmailTaken,
          intl,
          invalid,
          pristine,
          form,
          values,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          viewport,
        } = formRenderProps;

        const nameRequiredMessage = intl.formatMessage({
          id: 'CreateCompanyBasicsForm.nameRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'CreateCompanyBasicsForm.maxLength' },
          {
            maxLength: NAME_MAX_LENGTH,
          }
        );
        const maxLength60Message = validators.maxLength(maxLengthMessage, NAME_MAX_LENGTH);

        const emailRequiredMessage = intl.formatMessage({
          id: 'CreateCompanyBasicsForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'CreateCompanyBasicsForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const config = useConfiguration();
        const listingConfig = config.listing;
        const listingFields = listingConfig.listingFields.filter(l =>
          TAB_LISTING_FIELD_IDS.includes(l.key)
        );

        const companySize = listingFields.find(l => l.key === 'companySize');
        const companySizeOptions = companySize?.enumOptions ? companySize.enumOptions : [];

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || isEmailTaken;
        const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <ErrorMessage fetchErrors={fetchErrors} />

            <div className={css.fieldsWrapper}>
              <label>
                <FormattedMessage id="CreateCompanyBasicsForm.companyDetails" />
              </label>
              <div className={css.fieldFlex}>
                <FieldTextInput
                  id={`${formId}.name`}
                  name="name"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.namePlaceholder',
                  })}
                  maxLength={NAME_MAX_LENGTH}
                  validate={validators.composeValidators(
                    validators.required(nameRequiredMessage),
                    maxLength60Message
                  )}
                />
                <FieldTextInput
                  id={`${formId}.email`}
                  name="email"
                  className={css.field}
                  type="email"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.emailPlaceholder',
                  })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <OnChange name="email">
                  {(value, previous) => onEmailChangeWithDebounce({ email: value })}
                </OnChange>
              </div>

              <div className={css.fieldFlex}>
                <FieldSelect
                  id={`${formId}.companySize`}
                  name="companySize"
                  className={css.field}
                  validate={validators.required(companySize.saveConfig.requiredMessage)}
                  required
                >
                  <option disabled value="">
                    {companySize.saveConfig.placeholderMessage}
                  </option>
                  {companySizeOptions.map(o => {
                    return (
                      <option key={o.option} value={o.option}>
                        {o.label}
                      </option>
                    );
                  })}
                </FieldSelect>
                <FieldTextInput
                  id={`${formId}.website`}
                  name="website"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.websitePlaceholder',
                  })}
                />
              </div>

              <div className={css.fieldFlex}>
                <FieldTextInput
                  id={`${formId}.instagram`}
                  name="instagram"
                  className={classNames(css.field, css.instagramField)}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.instagramPlaceholder',
                  })}
                  validate={validators.validInstagramLink(
                    intl.formatMessage({ id: 'CreateCompanyBasicsForm.instagramInvalid' })
                  )}
                />
              </div>

              <label>
                <FormattedMessage id="CreateCompanyBasicsForm.addressDetails" />
              </label>

              <LocationSearchField
                intl={intl}
                placeholder={intl.formatMessage({
                  id: 'CreateCompanyBasicsForm.addressPlaceholder',
                })}
                isMobile={isMobileLayout}
                types={MAPBOX_MAP_CITY_TYPES}
                useCityPredictions
                onLocationChange={() => {}}
              />
              {/* <div className={css.fieldFlex}>
                <FieldTextInput
                  id={`${formId}.addressLine1`}
                  name="address.addressLine1"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.addressPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'CreateCompanyBasicsForm.addressRequired',
                    })
                  )}
                />

                <FieldTextInput
                  id={`${formId}.city`}
                  name="address.city"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.cityPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'CreateCompanyBasicsForm.cityRequired' })
                  )}
                />
              </div>
              <FieldTextInput
                id={`${formId}.zip`}
                name="address.zip"
                className={classNames(css.field, css.zipCodeField)}
                type="text"
                placeholder={intl.formatMessage({
                  id: 'CreateCompanyBasicsForm.zipPlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({ id: 'CreateCompanyBasicsForm.zipRequired' })
                )}
              /> */}

              {secondaryAddressVisible ? (
                <div className={css.secondaryAddress}>
                  <label className={css.secondaryAddressLabel}>
                    <FormattedMessage id="CreateCompanyBasicsForm.secondaryAddressDetails" />
                  </label>
                  <LocationSearchField
                    intl={intl}
                    placeholder={intl.formatMessage({
                      id: 'CreateCompanyBasicsForm.addressPlaceholder',
                    })}
                    isMobile={isMobileLayout}
                    types={MAPBOX_MAP_CITY_TYPES}
                    useCityPredictions
                    onLocationChange={() => {}}
                    fieldName="secondaryLocation"
                  />
                  {/* <div className={css.fieldFlex}>
                    <FieldTextInput
                      id={`${formId}.addressLine1`}
                      name="secondaryAddress.addressLine1"
                      className={css.field}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'CreateCompanyBasicsForm.addressPlaceholder',
                      })}
                    />

                    <FieldTextInput
                      id={`${formId}.city`}
                      name="secondaryAddress.city"
                      className={css.field}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'CreateCompanyBasicsForm.cityPlaceholder',
                      })}
                    />
                  </div>

                  <FieldTextInput
                    id={`${formId}.zip`}
                    name="secondaryAddress.zip"
                    className={css.zipCodeField}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'CreateCompanyBasicsForm.zipPlaceholder',
                    })}
                  /> */}
                </div>
              ) : null}

              {secondaryAddressVisible ? (
                <InlineTextButton
                  type="button"
                  className={classNames(
                    css.secondaryAddressButton,
                    css.deleteSecondaryAddressButton
                  )}
                  onClick={() => {
                    setSecondaryAddressVisible(false);
                    form.change('secondaryAddress', undefined);
                  }}
                >
                  <FormattedMessage id="CreateCompanyBasicsForm.deleteSecondaryAddressButton" />
                </InlineTextButton>
              ) : (
                <div style={{ marginTop: '20px' }}>
                  <InlineTextButton
                    type="button"
                    className={css.secondaryAddressButton}
                    onClick={() => setSecondaryAddressVisible(true)}
                  >
                    <FormattedMessage id="CreateCompanyBasicsForm.secondaryAddressButton" />
                  </InlineTextButton>
                </div>
              )}
            </div>

            <PanelFooter onRedirectToPreviousTab={onRedirectToPreviousTab}>
              <SecondaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </SecondaryButton>
            </PanelFooter>
          </Form>
        );
      }}
    />
  );
};

CreateCompanyBasicsFormComponent.defaultProps = {
  className: null,
  formId: 'CreateCompanyBasicsForm',
  fetchErrors: null,
};

CreateCompanyBasicsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default compose(
  injectIntl,
  withViewport
)(CreateCompanyBasicsFormComponent);
