/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { ensureCompanySearchOrUserType, getUserType, isCurrentUserAdmin } from '../../../util/data';
import { LISTING_TYPE_TALENT, LISTING_TYPE_COMPANY } from '../../../util/types';

import { InlineTextButton, NamedLink, Footer } from '../../../components';

import MenuIcon from '../MenuIcon';
import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const { isAuthenticated, currentUser, currentPage, onLogout, onCloseMobileMenu } = props;

  const currentUserType = getUserType(currentUser);
  const isCurrentUserCompany = ensureCompanySearchOrUserType(currentUserType);
  const isUserAdmin = isCurrentUserAdmin(currentUser);

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const loginOrLogoutButton = isAuthenticated ? (
    <InlineTextButton className={css.logoutButton} onClick={onLogout}>
      <FormattedMessage id="TopbarMobileMenu.logoutLink" />
    </InlineTextButton>
  ) : (
    <NamedLink className={css.loginButton} name="LoginPage">
      <FormattedMessage id="TopbarMobileMenu.loginLink" />
    </NamedLink>
  );

  const closeMenuButton = (
    <InlineTextButton className={css.closeButton} onClick={onCloseMobileMenu}>
      <MenuIcon className={css.closeButtonIcon} />
    </InlineTextButton>
  );

  const signupLink = isAuthenticated ? null : (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('SignupPage'))}
      name="LandingPage"
    >
      <FormattedMessage id="TopbarMobileMenu.signupLink" />
    </NamedLink>
  );

  const loginLink = isAuthenticated ? null : (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('LoginPage'))}
      name="LoginPage"
    >
      <FormattedMessage id="TopbarMobileMenu.loginLink" />
    </NamedLink>
  );

  const aboutLink = (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
      name="AboutPage"
    >
      <FormattedMessage id="TopbarMobileMenu.aboutLink" />
    </NamedLink>
  );

  const contactLink = (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('ContactPage'))}
      name="ContactPage"
    >
      <FormattedMessage id="TopbarMobileMenu.contactLink" />
    </NamedLink>
  );

  const searchLink = isAuthenticated ? (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
      name="SearchPage"
      params={{
        type: isCurrentUserCompany ? LISTING_TYPE_TALENT : LISTING_TYPE_COMPANY,
      }}
    >
      <FormattedMessage id="TopbarMobileMenu.searchLink" />
    </NamedLink>
  ) : null;

  const exportLink = isUserAdmin ? (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('ExportPage'))}
      name="ExportPage"
    >
      <FormattedMessage id="TopbarMobileMenu.exportLink" />
    </NamedLink>
  ) : null;

  const accountLink = isAuthenticated ? (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
      name="ProfileSettingsPage"
    >
      <FormattedMessage id="TopbarMobileMenu.accountLink" />
    </NamedLink>
  ) : null;

  const termsLink = (
    <NamedLink
      className={classNames(css.legalLink, currentPageClass('TermsOfServicePage'))}
      name="TermsOfServicePage"
    >
      <FormattedMessage id="TopbarMobileMenu.termsLink" />
    </NamedLink>
  );
  const privacyLink = (
    <NamedLink
      className={classNames(css.legalLink, currentPageClass('PrivacyPolicyPage'))}
      name="PrivacyPolicyPage"
    >
      <FormattedMessage id="TopbarMobileMenu.privacyLink" />
    </NamedLink>
  );

  return (
    <div className={css.root}>
      <div className={css.header}>
        {loginOrLogoutButton}
        {closeMenuButton}
      </div>
      <div className={css.content}>
        <div className={css.navigationLinks}>
          {signupLink}
          {loginLink}
          {aboutLink}
          {contactLink}
          {searchLink}
          {exportLink}
          {accountLink}
        </div>
        <div className={css.legalLinks}>
          {termsLink}
          {privacyLink}
        </div>
      </div>
      <div className={css.footer}>
        <Footer className={css.footerMenu} />
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentPage: null };

const { bool, func, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentPage: string,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
