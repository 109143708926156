import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { ensureCurrentUser, getUserType } from '../../util/data';
import { LISTING_PAGE_PENDING_APPROVAL_VARIANT, createSlug } from '../../util/urlHelpers';
import { NamedLink } from '../../components';

import css from './PendingApprovalBanner.module.css';

// Due to the layout structure, do not render the banner on the following pages
const disabledPages = ['SearchPage', 'ListingPage', 'ProfileCompletePage'];

const PendingApprovalBanner = props => {
  const {
    rootClassName,
    className,
    isAuthenticated,
    currentUser,
    currentUserListing,
    currentPage,
  } = props;

  if (!currentUserListing?.id?.uuid) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);
  const user = ensureCurrentUser(currentUser);
  const userType = getUserType(currentUser);

  const isPendingApproval =
    currentUserListing?.attributes?.state === LISTING_STATE_PENDING_APPROVAL;
  const showBanner =
    user.id && isAuthenticated && isPendingApproval && !disabledPages.includes(currentPage);

  return showBanner ? (
    <div className={classes}>
      <p className={css.text}>
        <FormattedMessage id="PendingApprovalBanner.message" />
      </p>
      <NamedLink
        className={css.button}
        name="ListingPageVariant"
        params={{
          id: currentUserListing.id.uuid,
          slug: createSlug(currentUserListing.attributes.title),
          type: userType,
          variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
        }}
      >
        <FormattedMessage id="LimitedAccessBanner.profileLink" />
      </NamedLink>
    </div>
  ) : null;
};

PendingApprovalBanner.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
};

const { bool, string } = PropTypes;

PendingApprovalBanner.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  currentPage: string,
};

export default PendingApprovalBanner;
