import React from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Import configs and util modules
import { intlShape, injectIntl } from '../../util/reactIntl';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  parse,
} from '../../util/urlHelpers';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';

// Import shared components
import { NamedRedirect, Page, Footer } from '../../components';
import { emailTaken } from '../../ducks/auth.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

// Import modules from this directory
import {
  requestCreateProfileDraft,
  requestPublishProfileDraft,
  requestUpdateProfile,
} from './CreateTalentPage.duck';
import CreateTalentWizard from './CreateTalentWizard/CreateTalentWizard';
import css from './CreateTalentPage.module.css';

// N.B. All the presentational content needs to be extracted to their own components
export const CreateTalentPageComponent = props => {
  const {
    history,
    location,
    intl,
    isAuthenticated,
    currentUser,
    onCreateProfileDraft,
    onPublishProfileDraft,
    onUpdateProfile,
    onEmailTaken,
    onManageDisableScrolling,
    isEmailTaken,
    page,
    signupError,
    params,
    scrollingDisabled,
  } = props;

  const { type } = params;

  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentProfile = page.profileData;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;

  if (isAuthenticated && currentUserLoaded && !page.updateInProgress) {
    const stateMaybe = currentProfile.address
      ? {
          state: { address: currentProfile.address },
        }
      : {};
    return <NamedRedirect name="ProfileCompletePage" params={{ type: 'talent' }} {...stateMaybe} />;
  }

  const {
    createListingDraftError = null,
    publishListingError = null,
    updateListingError = null,
  } = page;
  const errors = {
    createListingDraftError,
    publishListingError,
    updateListingError,
    signupError,
  };
  // TODO: is this dead code? (shouldRedirect is checked before)
  const newListingPublished = isDraftURI && type !== LISTING_PAGE_PARAM_TYPE_DRAFT;

  // Show form if user is posting a new listing or editing existing one
  const disableForm = page.redirectToListing && !showListingsError;

  const title = isNewListingFlow
    ? intl.formatMessage({ id: 'CreateTalentPage.titleCreateListing' })
    : intl.formatMessage({ id: 'CreateTalentPage.titleEditListing' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        className={css.topbar}
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        mobileClassName={css.mobileTopbar}
      />
      <CreateTalentWizard
        id="CreateTalentWizard"
        className={css.wizard}
        params={params}
        locationSearch={parse(location.search)}
        disabled={disableForm}
        errors={errors}
        currentProfile={currentProfile}
        newListingPublished={newListingPublished}
        history={history}
        onUpdateProfile={onUpdateProfile}
        onCreateProfileDraft={onCreateProfileDraft}
        onPublishProfileDraft={onPublishProfileDraft}
        onEmailTaken={onEmailTaken}
        isEmailTaken={isEmailTaken}
        onManageDisableScrolling={onManageDisableScrolling}
        updatedTab={page.updatedTab}
        updateInProgress={page.updateInProgress}
      />
      <Footer className={css.footer} />
    </Page>
  );
};

CreateTalentPageComponent.defaultProps = {
  listing: null,
  isAuthenticated: false,
  currentUser: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
};

CreateTalentPageComponent.propTypes = {
  onCreateProfileDraft: func.isRequired,
  onPublishProfileDraft: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateProfile: func.isRequired,
  page: object.isRequired,
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  signupError: propTypes.error,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: string.isRequired,
  }).isRequired,
  scrollingDisabled: bool.isRequired,
  onEmailTaken: func.isRequired,
  isEmailTaken: bool.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.CreateTalentPage;
  const { isEmailTaken } = state.auth;

  return {
    page,
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.user.currentUser,
    signupError: state.auth.signupError,
    scrollingDisabled: isScrollingDisabled(state),
    isEmailTaken,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: (tab, values, config) => dispatch(requestUpdateProfile(tab, values, config)),
  onCreateProfileDraft: (values, config) => dispatch(requestCreateProfileDraft(values, config)),
  onPublishProfileDraft: listingId => dispatch(requestPublishProfileDraft(listingId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onEmailTaken: email => dispatch(emailTaken(email)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CreateTalentPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CreateTalentPageComponent);

export default CreateTalentPage;
