import classNames from 'classnames';
import { bool, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Heading, NamedLink } from '../../../components';
import { userTypes } from '../../../config/configListing';
import { userType } from '../../../util/data';
import { propTypes } from '../../../util/types';
import { LISTING_PAGE_DRAFT_VARIANT } from '../../../util/urlHelpers';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import AsideNav, {
  FAQS,
  HOME,
  HOW_IT_WORKS,
  PROBLEM,
  REVIEWS,
  SOLUTION,
} from '../../AboutPage/AsideNav';
import SectionHome from '../../AboutPage/SectionHome';
import css from './HeroBoxes.module.css';
import { emailTaken } from '../../../ducks/auth.duck';

// Function to create a redirect URL based on profile data and other parameters
export const createRedirectURL = (profileData, name, fallbackName, tab) => {
  const redirectURL = profileData
    ? {
        name: name,
        params: {
          id: profileData.id,
          slug: profileData.name || 'draft',
          type: LISTING_PAGE_DRAFT_VARIANT,
          tab: tab,
        },
      }
    : { name: fallbackName };

  return redirectURL;
};

// Box component represents an individual box in the HeroBoxes component
const Box = ({ className, title, text, buttonText, linkProps }) => (
  <div className={classNames(css.box, className)}>
    <div className={css.boxContent}>
      <Heading className={css.boxHeading} as="h2">
        {title}
      </Heading>
      <p className={css.boxText}>{text}</p>
    </div>
    <NamedLink className={css.boxButton} {...linkProps}>
      {buttonText}
    </NamedLink>
  </div>
);

Box.propTypes = {
  title: string,
  text: string,
  buttonText: string,
};

// NonAuthenticatedHeroBoxes component renders the boxes for non-authenticated users
const NonAuthenticatedHeroBoxes = ({
  rootClassName,
  className,
  intl,
  talentProfileData,
  companyProfileData,
  initialValues,
  errors,
  isEmailTaken,
  sectionComponents,
  isMobileLayout,
  sectionComponentProps,
  isPopUp,
}) => {
  const classes = classNames(rootClassName || css.root, className);
  const isTalentDraft = talentProfileData?.id;
  const isCompanyDraft = companyProfileData?.id;

  const createTalentLinkProps = createRedirectURL(
    talentProfileData,
    'CreateTalentPage',
    'NewTalentPage',
    'basics'
  );
  const createCompanyLinkProps = createRedirectURL(
    companyProfileData,
    'CreateCompanyPage',
    'NewCompanyPage',
    'basics'
  );
  // When user types, we wait for new keystrokes a while before searching new content
  const DEBOUNCE_WAIT_TIME = 200;
  const history = useHistory();
  const routes = useRouteConfiguration();
  const dispatch = useDispatch();
  const config = useConfiguration();

  useEffect(() => {
    if (talentProfileData) {
      dispatch(emailTaken({ email: talentProfileData.email }));
    }
  }, []);

  const NAV = [HOME, PROBLEM, SOLUTION, HOW_IT_WORKS, REVIEWS, FAQS];

  if (isPopUp) {
    return (
      <div className={classes}>
        <Box
          className={css.talentBox}
          title={intl.formatMessage({ id: 'LandingPage.talentBoxTitle' })}
          text={intl.formatMessage({ id: 'LandingPage.talentBoxText' })}
          buttonText={
            isTalentDraft
              ? intl.formatMessage({ id: 'LandingPage.talentDraftBoxButton' })
              : intl.formatMessage({ id: 'LandingPage.talentBoxButton' })
          }
          linkProps={createTalentLinkProps}
        />
        <div className={css.separator} />
        <Box
          title={intl.formatMessage({ id: 'LandingPage.companyBoxTitle' })}
          text={intl.formatMessage({ id: 'LandingPage.companyBoxText' })}
          buttonText={
            isCompanyDraft
              ? intl.formatMessage({ id: 'LandingPage.companyDraftBoxButton' })
              : intl.formatMessage({ id: 'LandingPage.companyBoxButton' })
          }
          linkProps={createCompanyLinkProps}
        />
      </div>
    );
  }

  return (
    <div>
      <div className={css.newLandingPage}>
        <div className={css.asideContent}>
          <AsideNav intl={intl} isMobileLayout={isMobileLayout} NAV={NAV} />
        </div>
        <SectionHome
          key={HOME}
          sectionId={HOME}
          errors={errors}
          isEmailTaken={isEmailTaken}
          initialValues={initialValues}
          {...sectionComponentProps}
        />

        <div className={css.sections}>{sectionComponents}</div>
      </div>
    </div>
  );
};

NonAuthenticatedHeroBoxes.propTypes = {
  rootClassName: string,
  className: string,
  intl: object.isRequired,
  talentProfileData: object,
  companyProfileData: object,
  initialValues: object,
  isEmailTaken: bool,
  errors: object,
};

// AuthenticatedHeroBox component renders the box for authenticated users
const AuthenticatedHeroBox = ({ rootClassName, className, intl, currentUser }) => {
  const classes = classNames(rootClassName || css.root, css.authenticatedRoot, className);

  const currentUserType = userType(currentUser);
  const isCompany = currentUserType === userTypes['company'];

  return (
    <div className={classes}>
      <Box
        title={
          isCompany
            ? intl.formatMessage({ id: 'LandingPage.authenticatedCompanyBoxTitle' })
            : intl.formatMessage({ id: 'LandingPage.authenticatedTalentBoxTitle' })
        }
        text={
          isCompany
            ? intl.formatMessage({ id: 'LandingPage.authenticatedCompanyBoxText' })
            : intl.formatMessage({ id: 'LandingPage.authenticatedTalentBoxText' })
        }
        buttonText={
          isCompany
            ? intl.formatMessage({ id: 'LandingPage.authenticatedCompanyBoxButton' })
            : intl.formatMessage({ id: 'LandingPage.authenticatedTalentBoxButton' })
        }
        linkProps={{ name: 'SearchPage', params: { type: isCompany ? 'talent' : 'company' } }}
      />
    </div>
  );
};

AuthenticatedHeroBox.propTypes = {
  rootClassName: string,
  className: string,
  intl: object.isRequired,
  currentUser: propTypes.currentUser,
};

// HeroBoxes component acts as the main component for rendering the appropriate hero box based on user authentication
const HeroBoxes = ({ isAuthenticated, currentUser, ...rest }) => {
  if (isAuthenticated && currentUser) {
    return <AuthenticatedHeroBox currentUser={currentUser} {...rest} />;
  }

  return <NonAuthenticatedHeroBoxes {...rest} />;
};

HeroBoxes.propTypes = {
  isAuthenticated: bool.isRequired,
};

export default HeroBoxes;
