import classNames from 'classnames';
import { array, bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

// Import util modules
import { getGeolocation } from '../../../../util/geocoder';
import { FormattedMessage } from '../../../../util/reactIntl';
import { types as sdkTypes } from '../../../../util/sdkLoader';

// Import shared components
import { Heading } from '../../../../components';

// Import modules from this directory
import { updateorcreateMailChimpMember } from '../../CreateTalentPage.duck';
import PanelHeader from '../PanelHeader';
import CreateTalentWorkForm from './CreateTalentWorkForm';
import css from './CreateTalentWorkPanel.module.css';
// Import modules from this directory
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { injectIntl } from '../../../../util/reactIntl';

const { LatLng } = sdkTypes;

const ARCHITECTURE_KEY = 'architecture';
const DEFAULT_LOCATION_LAT_LNG = { lat: 39.3812661305678, lng: -97.9222112121185 };

const findTabIndex = (tabs, tab) => tabs.findIndex(t => t === tab) + 1;

const CreateTalentWorkPanel = props => {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);


  const {
    className,
    rootClassName,
    disabled,
    ready,
    onSubmit,
    onRedirectToPreviousTab,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    currentProfile,
    errors,
    config,
    marketplaceTabs,
    currentTab,
    tabLabel,
  } = props;
  const dispatch = useDispatch();
  const {email} = currentProfile||{};
  
  const classes = classNames(rootClassName || css.root, className);
  const initialValues = currentProfile
    ? {
        ...currentProfile,
        industry: currentProfile.industry ? currentProfile.industry : ARCHITECTURE_KEY,
      }
    : {
        industry: currentProfile.industry ? currentProfile.industry : ARCHITECTURE_KEY,
      };

  return (
    <div className={classes}>
      <PanelHeader
        tabLabel={tabLabel}
        order={findTabIndex(marketplaceTabs, currentTab)}
        length={marketplaceTabs.length}
      />
      <Heading className={css.title} as="h2">
        <FormattedMessage
          id="CreateTalentWorkPanel.createListingTitle"
          values={{ lineBreak: <br /> }}
        />
      </Heading>

      <CreateTalentWorkForm
        className={css.form}
        saveActionMsg={submitButtonText}
        initialValues={initialValues}
        onSubmit={async values => {
          try {
            setSubmitInProgress(true);

            const { 
              // address,
               industry, primarySpecialty, secondarySpecialty, interests,expertise,location } = values;
            // const talentFullAddress = address.city;
            // const libLoaded = typeof window !== 'undefined' && window.mapboxgl && window.mapboxSdk;
            // const geolocation =
            //   libLoaded && talentFullAddress
            //     ? await getGeolocation(talentFullAddress)
            //     : new LatLng(DEFAULT_LOCATION_LAT_LNG.lat, DEFAULT_LOCATION_LAT_LNG.lng);
            const { selectedPlace } = location;
            const geolocation = selectedPlace?.origin;
            const splitAddr1 = selectedPlace?.address.split(',');
            const address =splitAddr1? {
              addressLine1: splitAddr1[0],
              city: splitAddr1[1],
              zip: splitAddr1[2],
            }: {};
            const googleMapsUrl = selectedPlace?.url;

            const updateValues = {
              address,
              geolocation,
              industry,
              primarySpecialty,
              secondarySpecialty,
              interests,
              expertise,
              location,
              googleMapsUrl,
            };
            const mailchimpMember={
              email,
              // address:{
              //   addr1:'',
              //   city:address.city,
              //   state:'',
              //   zip:address.zip
              // },
              step:2,
              registertype:'Candidates'
            };

            dispatch(updateorcreateMailChimpMember(mailchimpMember));
            setSubmitInProgress(false);
            onSubmit(updateValues);
          } catch (e) {
            setSubmitError(true);
            setSubmitInProgress(false);
          }
        }}
        onRedirectToPreviousTab={onRedirectToPreviousTab}
        marketplaceCurrency={config.currency}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress || submitInProgress}
        fetchErrors={{
          ...errors,
          submitFormError: submitError,
        }}
        autoFocus
      />
    </div>
  );
};

CreateTalentWorkPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  marketplaceTabs: null,
  currentTab: 'basics',
  tabLabel: null,
};

CreateTalentWorkPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  marketplaceTabs: array.isRequired,
  currentTab: string.isRequired,
  tabLabel: string.isRequired,
};

export default CreateTalentWorkPanel;
