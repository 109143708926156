import React, { Component } from 'react';
import { bool, func, string, array } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../../util/reactIntl';

import { Form, LocationAutocompleteInput } from '../../../components';

import css from './LocationFilter.module.css';

const identity = v => v;

export const LocationSearchField = props => {
  const {
    intl,
    inputRootClassName,
    inputClassName,
    predictionsClassName,
    placeholder,
    isMobile,
    types,
    useCityPredictions,
    inputRef,
    onLocationChange,
    fieldName,
  } = props;
  const inputClasses = classNames(inputClassName || css.input);
  const predictionsClasses = classNames(predictionsClassName || css.predictions);

  const placeholderText = isMobile
    ? intl.formatMessage({ id: 'LocationFilter.mobilePlaceholder' })
    : placeholder
    ? placeholder
    : intl.formatMessage({ id: 'LocationFilter.placeholder' });
  return (
    <Field
      name={fieldName??"location"}
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          onLocationChange(value);
        };

        const typesMaybe = isMobile ? {} : { types };
        return (
          <LocationAutocompleteInput
            className={inputRootClassName}
            iconClassName={css.searchIcon}
            inputClassName={inputClasses}
            focusClassName={css.inputFocused}
            predictionsClassName={predictionsClasses}
            placeholder={placeholderText}
            closeOnBlur={!isMobile}
            inputRef={inputRef}
            input={{ ...restInput, onChange: searchOnChange }}
            meta={meta}
            useCityPredictions={useCityPredictions}
            {...typesMaybe}
          />
        );
      }}
    />
  );
};

class LocationFilterComponent extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
  }

  onChange(location) {
    const { onSubmit } = this.props;

    if (location.selectedPlace) {
      const { search, selectedPlace } = location;
      const { bounds } = selectedPlace;

      const address = search && search !== '';
      const addressMaybe = address ? { address } : {};

      onSubmit({
        ...addressMaybe,
        bounds,
      });
    }
  }

  render() {
    const { handleSubmit, types, ...restOfProps } = this.props;

    return (
      <FinalForm
        {...restOfProps}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            inputRootClassName,
            inputClassName,
            predictionsClassName,
            intl,
            placeholder,
            isMobile,
            useCityPredictions,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const inputRootClass = inputRootClassName || css.inputRoot;

          // Location search: allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();

          return (
            <Form
              className={classes}
              onSubmit={preventFormSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              <LocationSearchField
                intl={intl}
                inputRootClassName={inputRootClass}
                inputClassName={inputClassName}
                predictionsClassName={predictionsClassName}
                placeholder={placeholder}
                isMobile={isMobile}
                types={types}
                useCityPredictions={useCityPredictions}
                inputRef={this.setSearchInputRef}
                onLocationChange={this.onChange}
              />
            </Form>
          );
        }}
      />
    );
  }
}

LocationFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClassName: null,
  inputClassName: null,
  predictionsClassName: null,
  placeholder: null,
  isMobile: false,
  useCityPredictions: false,
};

LocationFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClassName: string,
  inputClassName: string,
  predictionsClassName: string,
  placeholder: string,
  types: array,
  onSubmit: func.isRequired,
  isMobile: bool,
  useCityPredictions: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LocationFilter = injectIntl(LocationFilterComponent);

export default LocationFilter;
